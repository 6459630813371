<template>
  <div id="routes">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">Route Management</span>
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <v-card>
      <v-row class="px-2 pt-4 pb-0 ma-0">
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="sqOnID"
            label="Route ID"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
            @change="val => addToSearchHistory(val, 'sqOnID')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="sqOnShortName"
            label="Route code"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
            @change="val => addToSearchHistory(val, 'sqOnShortName')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="sqOnLongName"
            label="Route name"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
            @change="val => addToSearchHistory(val, 'sqOnLongName')"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="filteredRoutes"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :custom-sort="customSort"
        :page.sync="page"
        :must-sort="true"
      >
        <template #[`item.route_id`]="{ item }">
          <router-link
            :to="{ name: 'next-stop-route-config', params: { id: item.route_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="text-capitalize font-weight-semibold text--primary">{{ item.route_id }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template #[`item.route_short_name`]="{ item }">
          <router-link
            :to="{ name: 'next-stop-route-config', params: { id: item.route_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <h2 class="text-sm font-weight-bold mb-1 mt-1">
              <route-badge :route="item" :size="38" avatar-class="vehicle-avatar" text-class="text-1xl"></route-badge>
            </h2>
          </router-link>
        </template>

        <template #[`item.route_long_name`]="{ item }">
          <router-link
            :to="{ name: 'next-stop-route-config', params: { id: item.route_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.route_long_name }}</span>
          </router-link>
        </template>

        <template #[`item.route_type`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">
            <v-tooltip bottom class="text--primary">
              <template #activator="{ on, attrs }">
                <v-icon size="20" v-bind="attrs" v-on="on">
                  {{ routeTypePresentation(item.route_type).icon }}
                </v-icon>
              </template>
              <span>{{ routeTypePresentation(item.route_type).text }}</span>
            </v-tooltip>
          </span>
        </template>

        <template #[`item.agency_id`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.agency_id }}</span>
        </template>

        <template #[`item.config`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  :to="{ name: 'next-stop-route-config', params: { id: item.route_id } }"
                  v-on="on"
                >
                  <v-icon color="primary" size="18">
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Edit configuration for route
                <span
                  class="route_presentation"
                  :style="{ color: item.route_text_color, backgroundColor: item.route_color }"
                >
                  {{ item.route_short_name }}
                </span>
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiRoutes, mdiSquareEditOutline } from "@mdi/js"
import { mapState } from "vuex"
import RouteBadge from "@/components/route/Badge.vue"
import globalHelpers from "@/mixins/globalHelpers"
import urlHelpers from "@/mixins/urlHelpers"

export default {
  name: "RouteList",
  metaInfo: {
    title: "Routes",
  },
  components: {
    RouteBadge,
  },
  mixins: [globalHelpers, urlHelpers],
  data() {
    return {
      sqOnID: "",
      sqOnShortName: "",
      sqOnLongName: "",
      sortBy: "route_short_name",
      sortDesc: false,
      tableColumns: [
        { text: "Route ID", value: "route_id" },
        { text: "Route code", value: "route_short_name" },
        { text: "Name", value: "route_long_name" },
        { text: "Mode", value: "route_type", sortable: false },
        { text: "Agency", value: "agency_id" },
        {
          text: "Edit",
          value: "config",
          align: "center",
          sortable: false,
        },
      ],
      icons: {
        mdiRoutes,
        mdiSquareEditOutline,
      },
    }
  },
  computed: {
    ...mapState({ stops: state => state.gtfs.stops, routes: state => state.gtfs.routes }),
    filteredRoutes() {
      const filtered = this.routes.filter(route => {
        let include = true

        if (include && this.sqOnID.length) {
          include = route.route_id.toString().startsWith(this.sqOnID.toLowerCase())
        }

        if (include && this.sqOnShortName.length) {
          include = route.route_short_name.toLowerCase().startsWith(this.sqOnShortName.toLowerCase())
        }

        if (include && this.sqOnLongName.length) {
          include = route.route_long_name.toLowerCase().includes(this.sqOnLongName.toLowerCase())
        }

        return include
      })

      return filtered
    },
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        const sortA = a[sortByField]
        const sortB = b[sortByField]

        // Special handling of field sorting when required
        if (sortByField === "route_short_name") {
          return sortA - sortB
        }

        if (sortA > sortB) return 1
        if (sortA < sortB) return -1

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
  },
}
</script>
